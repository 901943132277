import styled from 'styled-components';
import { breakpoint, font } from 'styles/globals';
import { getColor, Theme } from 'styles/theme';

interface SubscriptionPleaContainerProps {
  $hasContent: string | null;
  $showSubscriptionPlea: boolean;
  theme: Theme;
}

const SubscriptionPleaContainer = styled.div<SubscriptionPleaContainerProps>`
    display: ${({ $hasContent, $showSubscriptionPlea }) => ($hasContent && $showSubscriptionPlea ? 'block' : 'none')};
    font-family: ${font.graphikCond};
    font-size: 24px;
    line-height: 150%;
    width: 100%;

    p {
      padding: 0px;
    }

    a {
      font-weight: 400;
      color: ${getColor('textLink')};
      text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &:active {
      color: ${getColor('neutralBlack')};
      text-decoration: none;
    }
  }

    &.longform {
      border-bottom: 2px solid ${getColor('neutralDarkGray')};

      p {
        margin: 12px 0px;
      }

      &:after {
        display: block;
        border-bottom: 2px solid ${getColor('neutralDarkGray')};
        content: '';
        margin: 3px 0px;
      }
    }

    &.article {
      p {
        margin: 0 0 32px;
      }

      @media (${breakpoint.lgMin}) {
        width: 95%;
      }

      @media (${breakpoint.xxlMin}) {     
        &.article {          
          padding-left: -20px;
      }
    }
  }
`;

export default SubscriptionPleaContainer;
